import { trackEvent } from '../../lib/analytics';

const trackSitterEvent = (action) => {
    trackEvent('Sitter Profile', action);
};

export const trackSitterApplicationEvent = (action, eventLabel) => {
    trackEvent('SitterApplication', action, eventLabel);
};

const trackMobileSitterEvent = (action) => {
    trackSitterEvent('Mobile - ' + action);
};

const trackDesktopSitterEvent = (action) => {
    trackSitterEvent('Desktop - ' + action);
};

const trackSitterEventWithMediaType = (mobile, action) => {
    if (mobile) {
        trackMobileSitterEvent(action);
    } else {
        trackDesktopSitterEvent(action);
    }
};

export const trackSitterBookNowButton = (mobile) => {
    trackSitterEventWithMediaType(mobile, 'Click Book Now');
};

export const trackSitterContactButton = (mobile) => {
    trackSitterEventWithMediaType(mobile, 'Click Contact');
};

export const trackClickAdditionalServices = (mobile) => {
    trackSitterEventWithMediaType(mobile, 'Click Additional Services');
};

export const trackSitterReviewsPageChange = () => {
    trackSitterEvent('Sitter reviews - Page changed');
};

export const trackClickSeeMorePhotos = (mobile) => {
    trackSitterEventWithMediaType(mobile, 'See more photos - Clicked');
};

export const trackBookNowClickContinue = (mobile) => {
    trackSitterEventWithMediaType(mobile, 'Booking - Click Continue');
};

export const trackSitterPageUnknownError = () => {
    trackSitterEvent('Unknown error');
};

export const trackSitterPageNotFound = () => {
    trackSitterEvent('Sitter not found');
};
